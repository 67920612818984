export const black = "#4a4e4d";
export const greenOne = "#0e9aa7";
export const greenTwo = "#3da4ab";
export const yellow = "#f6cd61";
export const red = "#fe8a71";

export default {
    black,
    greenOne,
    greenTwo,
    yellow,
    red
}
