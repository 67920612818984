import React from "react";
import { Line } from "react-chartjs-2";
import { ChartDataSets } from "chart.js";
import { greenOne } from "../../constants/colors";

const HumidChart: React.FC<{ data: ChartDataSets["data"] }> = ({ data }) => {
    return (
            <Line 
                data={{
                    datasets: [{
                        label: "Humidity [%]",
                        fill: true,
                        backgroundColor: greenOne,
                        borderColor: greenOne,
                        pointRadius: 0,
                        data: data,
                    }]
                }} 
                options={{
                    scales: {
                        xAxes: [{
                            type: "time",
                            distribution: 'series'
                        }],
                        yAxes: [{
                            ticks: {
                                suggestedMin: 0,
                                suggestedMax: 100
                            }
                        }]
                    }
                }}
            />
    )
}

export default HumidChart;
