import React from 'react';

import Logo from "../components/Logo";
import {createUseStyles} from "react-jss";
import { black, red } from "../constants/colors";

const useStyles = createUseStyles({
  wrapper: {
    textAlign: "center",
  },
  banner: {
    backgroundColor: "white",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: black,
  },
  link: {
    textDecoration: "none",
    color: black,
    position: "absolute",
    right: 24,
    bottom: 24,
    "&:hover": {
      color: red, 
    }
  },
  "@media only screen and (max-width: 400px)": {
    wrapper: {
      textAlign: "left",
    }
  }
});

const Home: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.banner}>
        <Logo/>
        <a className={classes.link} href="mailto:mail@christianpoulsen.dk">mail@christianpoulsen.dk</a>
      </div>
    </div>
  );
}

export default Home;
