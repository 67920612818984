import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Home from '../pages/Home';
import Sensors from "../pages/Sensors";
import Error from "../pages/Error";

const App: React.FC = () => {
  return (
    <Router>
        <Switch>
          <Route path="/sensors">
            <Sensors />
          </Route>
          <Route path="/">
            <Home />
          </Route>
          <Route path="*">
            <Error />
          </Route>
        </Switch>
    </Router>
  )
}

export default App;
