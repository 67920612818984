import React from 'react';
import { createUseStyles } from 'react-jss';
import { black } from '../constants/colors';

const useStyles = createUseStyles({
  logo: {
    marginBottom: "48px",
  },
  name: {
    fontSize: "calc(32px + 1vw)",
    margin: 0,
  },
  underline: {
    width: "calc(200px + 6vw)",
    height: "2px",
    backgroundColor: black,
    position: "relative",
    top: "8px",
  },
  tagline: {
    fontSize: "calc(8px + 0.7vw)",
    color: black,
    backgroundColor: "white",
    padding: "0 12px",
    position: "relative",
    bottom: "8px",
    left: "130px",
  },
  "@media only screen and (max-width: 400px)": {
    name: {
      width: "200px",
    },
    tagline: {
      bottom: "-16px",
      left: 0,
    }
  }
})

const Logo: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.logo}>
        <p className={classes.name}>CHRISTIAN POULSEN</p>
        <div className={classes.underline} />
        <span className={classes.tagline}>Full-Stack Web Engineer</span>
    </div>
  );
}

export default Logo;
