import React, { useEffect, useState } from "react";
import TempChart from "./TempChart";
import HumidChart from "./HumidChart";
import {createUseStyles} from "react-jss";
import { black } from "../../constants/colors";

const useStyles = createUseStyles({
    container: {
        display: "flex", 
        flexDirection: "column", 
        justifyContent: "center", 
        alignItems: "center",
        color: black,
    },
    box: {
        width: 920,
    }
})

interface RawMeasurement {
    temperature: number;
    humidity: number;
    date: number;
}

export interface Measurement {
    temperature: number;
    humidity: number;
    date: Date;
}

const Sensors: React.FC = () => {
    const [measurements, setMeasurements] = useState<Measurement[]>([]);
    const classes = useStyles();

    useEffect(() => {
        const getMeasurements = async () => {
            fetch("https://d9709e14625b.ngrok.io", {
                method: "GET",
                cache: "no-cache",
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                },
                redirect: "follow",
                referrerPolicy: "no-referrer",
            })
            .then(res => res.json())
            .then(data => {
                const processed = data.map((m: RawMeasurement) => ({ ...m, date: new Date(m.date)}))
                setMeasurements(processed);
            })
            .catch(err => console.error(err));
        }
        getMeasurements();
    }, [])

    return (
        <div className={classes.container}>
            <h1>Sensors</h1>
            <div className={classes.box}>
                <TempChart data={measurements.map(m => ({ y: m.temperature, t: m.date }))} />
            </div>
            <div className={classes.box}>
                <HumidChart data={measurements.map(m => ({ y: m.humidity, t: m.date }))} />
            </div>
        </div>
    )
}

export default Sensors;
