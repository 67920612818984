import React from "react";
import { Line } from "react-chartjs-2";
import { ChartDataSets } from "chart.js";
import { yellow } from "../../constants/colors";

const TempChart: React.FC<{ data: ChartDataSets["data"] }> = ({ data }) => {
    return (
            <Line 
                data={{
                    datasets: [{
                        label: "Temperature [C]",
                        fill: false,
                        borderColor: yellow,
                        pointRadius: 0,
                        data: data,
                    }]
                }} 
                options={{
                    scales: {
                        xAxes: [{
                            type: "time",
                            distribution: 'series'
                        }],
                        yAxes: [{
                            ticks: {
                                suggestedMin: 20,
                                suggestedMax: 30
                            }
                        }]
                    },
                }}
            />
    )
}

export default TempChart;
